<template>
  <b-form
    v-if="elementData"
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Autorizaciones</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="number"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.number') }}</span>
                <b-form-input
                  id="number"
                  v-model="medical_order"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="date"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.generation_date') }}</span>
                <b-form-input
                  id="date"
                  v-model="dateParsed"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label-for="service_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.service_type') }}</span>
                <b-form-input
                  id="service_type"
                  v-model="elementData.service_type.value"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
            >
              <date-picker
                :element-required="true"
                element-field="scheduled_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.scheduled_date')"
              />
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label-for="expireDate"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.expireDate') }}</span>
                <b-form-input
                  id="expireDate"
                  v-model="expireDateParsed"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label-for="medical_order_status"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.medical_order_status') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.medical_order_status')"
                >
                  <b-form-input
                    id="medical_order_status"
                    v-model="elementData.medical_order_status.value"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="case_number"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.case_number') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.case_number')"
                >
                  <b-form-input
                    id="case_number"
                    v-model="elementData.case_number"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="arl_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.arl') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.arl')"
                >
                  <b-form-input
                    id="arl_name"
                    v-model="elementData.arl_name"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="internal_code"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.internal_code') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.internal_code')"
                >
                  <b-form-input
                    id="internal_code"
                    v-model="elementData.internal_code"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
      <div v-if="elementData.service_type.key=='LABORATORIES'">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Trabajador</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="patient_identity"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                  <b-form-input
                    id="patient_identity"
                    v-model="elementData.patient_identity"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="patient_name"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                  <b-form-input
                    id="patient_name"
                    v-model="elementData.patient_name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
              >
                <b-form-group
                  label-for="patient_birthdate"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_birthdate') }}</span>
                  <b-form-input
                    id="patient_birthdate"
                    v-model="birthdate"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
              >
                <b-form-group
                  label-for="patient_age"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_age') }}</span>
                  <b-form-input
                    id="patient_age"
                    v-model="age"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>

          <div v-if="elementData.request_destination.key=='SOURCE'">
            <b-card-header>
              <div class="text-primary h2">
                <feather-icon
                  icon="StarIcon"
                  size="25"
                  class="mr-0 mr-sm-50"
                  variant="primary"
                />
                <span class="d-none d-sm-inline">Información fuente</span>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="source_identity"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_identity') }}</span>
                    <b-form-input
                      id="source_identity"
                      v-model="elementData.case.source_identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-group
                    label-for="source_name"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_name') }}</span>
                    <b-form-input
                      id="source_name"
                      v-model="source_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="source_birthdate"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_birthdate') }}</span>
                    <b-form-input
                      id="source_birthdate"
                      v-model="sourceBirthdate"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                >
                  <b-form-group
                    label-for="source_age"
                  >
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.source_age') }}</span>
                    <b-form-input
                      id="source_age"
                      v-model="sourceAge"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </div>
          <b-card-body>
            <b-row>
              <b-col
                cols="6"
              >
                <b-form-group
                  label-for="last_consult"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.last_consult') }}</span>
                  <b-form-input
                    id="last_consult"
                    v-model="elementData.step.step_type.value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="elementData.next_consult"
                cols="6"
              >
                <b-form-group
                  label-for="next_consult"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.next_consult') }}</span>
                  <b-form-input
                    id="next_consult"
                    v-model="elementData.next_consult.value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col
                cols="6"
              >
                <b-form-group
                  label-for="request_destination"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.request_destination') }}</span>
                  <b-form-input
                    id="request_destination"
                    v-model="elementData.request_destination.value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="2"
              >
                <b-form-group
                  label-for="element-status"
                >
                  <b-form-checkbox
                    v-model="elementData.requires_freezer"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    value="1"
                    unchecked-value="0"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.requires_freezer') }}</span>
                </b-form-group>
              </b-col>
              <b-col
                v-if="elementData.requires_freezer == 1"
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="freezer_reception_status_value"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.reception_status') }}</span>
                  <b-form-input
                    id="freezer_reception_status_value"
                    v-model="elementData.freezer_reception_status_value"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
      <div v-else>
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Trabajador</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="patient_identity"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_identity') }}</span>
                  <b-form-input
                    id="patient_identity"
                    v-model="elementData.patient_identity"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label-for="patient_name"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_name') }}</span>
                  <b-form-input
                    id="patient_name"
                    v-model="elementData.patient_name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
              >
                <b-form-group
                  label-for="patient_birthdate"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_birthdate') }}</span>
                  <b-form-input
                    id="patient_birthdate"
                    v-model="birthdate"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
              >
                <b-form-group
                  label-for="patient_age"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.patient_age') }}</span>
                  <b-form-input
                    id="patient_age"
                    v-model="age"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>

      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Laboratorio</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label-for="clinical_laboratory_lab"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_lab') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_laboratory_lab')"
                >
                  <v-select
                    v-model="elementData.clinical_laboratory_id"
                    :options="localClinicalLaboratoryList"
                    label="name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onLaboratoryChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="clinical_department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_department') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_department')"
                >
                  <v-select
                    v-model="elementData.clinical_department_id"
                    :options="clinicalLaboratoryDepartmentList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onDepartmentChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="clinical_city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_city') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_city')"
                >
                  <v-select
                    v-model="elementData.clinical_city_id"
                    :options="clinicalLaboratoryCityList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onCityChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="clinical_laboratory_headquarter">
                <feather-icon icon="StarIcon" class="mr-75"/>
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter') }}</span>
                <validation-provider #default="{ errors }" :name="$t(resourcesElementName + '.object.clinical_laboratory_headquarter')">
                  <v-select
                    v-model="elementData.clinical_laboratory_headquarter_id"
                    :options="clinicalHeadquarterList"
                    label="name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onHeadquarterChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label-for="clinical_laboratory_headquarter_address"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter_address') }}</span>
                <b-form-input
                  id="clinical_laboratory_headquarter_address"
                  v-model="address"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="elementData.service_type.key=='LABORATORIES'">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="element-status"
              >
                <b-form-checkbox
                  v-model="elementData.same_laboratory"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                  value="1"
                  unchecked-value="0"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.same_laboratory') }}</span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card v-if="elementData.same_laboratory==0">
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Laboratorio procesamiento de la muestra</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label-for="clinical_sample_laboratory_lab"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_lab') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_laboratory_lab')"
                >
                  <v-select
                    v-model="elementData.clinical_sample_laboratory_id"
                    :options="localClinicalSampleLaboratoryList"
                    label="name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onSampleLaboratoryChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="clinical_sample_department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_department') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_department')"
                >
                  <v-select
                    v-model="elementData.clinical_sample_department_id"
                    :options="clinicalLaboratoryDepartmentList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onSampleDepartmentChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="clinical_sample_city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_city') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_city')"
                >
                  <v-select
                    v-model="elementData.clinical_sample_city_id"
                    :options="clinicalSampleLaboratoryCityList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onSampleCityChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label-for="clinical_sample_laboratory_headquarter"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.clinical_laboratory_headquarter')"
                >
                  <v-select
                    v-model="elementData.clinical_sample_laboratory_headquarter_id"
                    :options="clinicalSampleHeadquarterList"
                    label="name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onSampleHeadquarterChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label-for="clinical_sample_laboratory_headquarter_address"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory_headquarter_address') }}</span>
                <b-form-input
                  id="clinical_laboratory_headquarter_address"
                  v-model="sample_address"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card>
        <groups-tab
          :element-data="elementData"
          :service-bundle-list="serviceBundleList"
        />
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import moment from 'moment'
import router from '@/router'
import vSelect from 'vue-select'
import GroupsTab from './EditGroups.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    GroupsTab, DatePicker, BFormCheckbox, BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    destinationOrdersList: {
      type: Array,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryDepartmentList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    clinicalHeadquarterList: {
      type: Array,
      required: true,
    },

    clinicalSampleLaboratoryCityList: {
      type: Array,
      required: true,
    },
    clinicalSampleHeadquarterList: {
      type: Array,
      required: true,
    },
    holidays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: '',
      vigence_date_render: '',
      required,
      dateParsed: undefined,
      expireDateParsed: undefined,
      birthdate: '',
      sourceBirthdate: '',
      address: '',
      localClinicalLaboratoryList: [],
      localClinicalSampleLaboratoryList: [],
      sample_address: '',
      medical_order: undefined,
    }
  },
  computed: {
    age: {
      get() {
        if (this.elementData.case.patient.birthdate) {
          let date = this.elementData.case.patient.birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
    sourceAge: {
      get() {
        if (this.elementData.case.source_birthdate) {
          let date = this.elementData.case.source_birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
    source_name: {
      get() {
        const names = [
          this.elementData.case.source_first_name,
          this.elementData.case.source_second_name,
          this.elementData.case.source_first_surname,
          this.elementData.case.source_second_surname,
        ]

        const filteredNames = names.filter(name => name && name.trim() !== '')

        return filteredNames.join(' ')
      },
    },
  },
  watch: {
    'elementData.authorization_date': function (newValue) {
      this.update_vigence_date()
    },
    'elementData.scheduled_date': function (newValue) {
      this.update_schedule_date()
    },
    clinicalHeadquarterList(newValue) {
      if (this.clinicalHeadquarterList.length > 0 && this.elementData.clinical_laboratory_headquarter_id) {
        const hq = this.clinicalHeadquarterList.find(element => element.id == this.elementData.clinical_laboratory_headquarter_id)
        this.address = hq ? hq.address : this.address
      }
    },
    clinicalSampleHeadquarterList(newValue) {
      if (this.clinicalSampleHeadquarterList.length > 0 && this.elementData.clinical_sample_laboratory_headquarter_id) {
        const hqs = this.clinicalSampleHeadquarterList.find(element => element.id == this.elementData.clinical_sample_laboratory_headquarter_id)
        this.sample_address = hqs ? hqs.address : this.sample_address
      }
    },
  },
  mounted() {
    let prefix = 'LAB-'

    switch (this.elementData.service_type.key) {
      case 'VACCINATION':
        prefix = 'VAC-'
        this.process_dosage_dates()
        break
      case 'MEDICINES':
        this.elementData.proposed_date = this.elementData.date
        prefix = 'MED-'
        break
      case 'FOLLOWUPS':
        prefix = 'CON-'
        break
    }

    this.medical_order = prefix + String(this.elementData.id).padStart(6, '0')
    if (!this.elementData.scheduled_date) {
      console.log('proposed_date', this.elementData.proposed_date)
      this.elementData.scheduled_date = this.elementData.proposed_date ? moment(this.elementData.proposed_date, 'DD/MM/YYYYTHH:mm:ss') : moment()
    }
    this.expireDateParsed = undefined
    this.resourcesElementName = 'arl_codes'

    this.$root.$emit('fetch-clinical-cities', this.elementData.clinical_department_id)
    this.$root.$emit('fetch-clinical-laboratory-headquarters', this.elementData.clinical_laboratory_id)
    this.$root.$emit('fetch-clinical-sample-cities', this.elementData.clinical_sample_department_id)
    this.$root.$emit('fetch-clinical-sample-laboratory-headquarters', this.elementData.clinical_sample_laboratory_id)
    //this.$root.$emit('filter-clinical-headquarters-by-city', this.elementData.clinical_laboratory_id, this.elementData.clinical_city_id)
    //this.$root.$emit('filter-clinical-sample-headquarters-by-city', this.elementData.clinical_sample_laboratory_id, this.elementData.clinical_sample_city_id)

    this.localClinicalLaboratoryList = [...this.clinicalLaboratoryList]
    const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_laboratory_id)
    this.address = laboratory ? laboratory.address : ''

    this.localClinicalSampleLaboratoryList = [...this.clinicalLaboratoryList]
    const sample_laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_sample_laboratory_id)
    this.sample_address = sample_laboratory ? sample_laboratory.address : ''

    this.update_schedule_date()
    this.update_vigence_date()
    this.dateParsed = this.formatDate(this.elementData.date)
    this.birthdate = this.formatDate(this.elementData.case.patient.birthdate)
    this.sourceBirthdate = this.formatDate(this.elementData.case.source_birthdate)
  },
  methods: {
    router() {
      return router
    },
    update_schedule_date() {
      console.log('updating schedule_date')
      this.elementData.expire_date = this.processScheduleDate(this.elementData.scheduled_date)
      this.expireDateParsed = this.formatDate(this.elementData.expire_date)
      this.expireDateParsed = this.expireDateParsed == 'Invalid date' || this.expireDateParsed == 'Fecha inválida' ? '' : this.expireDateParsed
    },
    onDepartmentChange() {
      this.elementData.clinical_city_id = null
      this.$root.$emit('fetch-clinical-cities', this.elementData.clinical_department_id)
      this.$root.$emit('filter-clinical-headquarters-by-department', this.elementData.clinical_laboratory_id, this.elementData.clinical_department_id)
    },
    onCityChange() {
      // Filter laboratory list by city
      this.elementData.clinical_laboratory_headquarter_id = null
      this.$root.$emit('filter-clinical-headquarters-by-city', this.elementData.clinical_laboratory_id, this.elementData.clinical_city_id)
    },
    onLaboratoryChange() {
      this.elementData.clinical_department_id = null
      this.elementData.clinical_city_id = null
      this.elementData.clinical_laboratory_headquarter_id = null
      this.$root.$emit('fetch-clinical-laboratory-headquarters', this.elementData.clinical_laboratory_id)
      const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_laboratory_id)
      this.address = laboratory ? laboratory.address : ''
    },
    onHeadquarterChange() {
      this.address = this.elementData.clinical_laboratory_headquarter_id ? this.clinicalHeadquarterList.find(element => element.id == this.elementData.clinical_laboratory_headquarter_id).address : this.address
    },

    onSampleDepartmentChange() {
      this.elementData.clinical_sample_city_id = null
      this.$root.$emit('fetch-clinical-sample-cities', this.elementData.clinical_sample_department_id)
      this.$root.$emit('filter-clinical-sample-headquarters-by-department', this.elementData.clinical_sample_laboratory_id, this.elementData.clinical_sample_department_id)
    },
    onSampleCityChange() {
      // Filter laboratory list by city
      this.elementData.clinical_sample_laboratory_headquarter_id = null
      this.$root.$emit('filter-clinical-sample-headquarters-by-city', this.elementData.clinical_sample_laboratory_id, this.elementData.clinical_sample_city_id)
    },
    onSampleLaboratoryChange() {
      this.elementData.clinical_sample_department_id = null
      this.elementData.clinical_sample_city_id = null
      this.elementData.clinical_sample_laboratory_headquarter_id = null
      this.$root.$emit('fetch-clinical-sample-laboratory-headquarters', this.elementData.clinical_sample_laboratory_id)
      const laboratory = this.clinicalLaboratoryList.find(element => element.id == this.elementData.clinical_sample_laboratory_id)
      this.sample_address = laboratory ? laboratory.address : ''
    },
    onSampleHeadquarterChange() {
      this.sample_address = this.elementData.clinical_sample_laboratory_headquarter_id ? this.clinicalSampleHeadquarterList.find(element => element.id == this.elementData.clinical_sample_laboratory_headquarter_id).address : this.sample_address
    },

    processScheduleDate(newValue) {
      let count = 0
      const date = moment(newValue, 'DD/MM/YYYY')
      while (count < 5) {
        do {
          date.add(1, 'days')
        } while (this.isHoliday(date))
        count += 1
      }
      return date.toDate()
    },
    isHoliday(date) {
      const mdate = moment(date)
      const saturday = moment().isoWeekday('Saturday').weekday()
      const sunday = moment().isoWeekday('Sunday').weekday()
      if (mdate.weekday() == saturday || mdate.weekday() == sunday) {
        return true
      }
      for (const holiday of this.holidays) {
        if (holiday.isSame(mdate)) {
          return true
        }
      }
      return false
    },
    formatDate(value) {
      const date = moment(value, 'DD/MM/YYYY')

      if (date.isValid()) {
        return date.format('DD/MM/YYYY')
      }
      return ''
    },
    update_vigence_date() {
      console.log('updating vigence_date')
      let service_vigency = 0
      if (this.elementData.service_bundle_id && this.serviceBundleList && this.serviceBundleList.length > 0) {
        service_vigency = this.serviceBundleList.find(element => element.id == this.elementData.service_bundle_id).validity
      }
      let authorization_date = moment()
      if (this.elementData.authorization_date) {
        authorization_date = moment(this.elementData.authorization_date)
      }
      this.elementData.vigence_date = authorization_date.add(service_vigency, 'days').toDate()
      this.vigence_date_render = moment(this.elementData.vigence_date).format('DD/MM/YYYY')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('service_type' in this.elementData) {
            delete this.elementData.service_type
          }
          this.$parent.save(this.elementData)
        }
      })
    },
    process_dosage_dates() {
      const first_dosage = this.elementData.bundles[0]?.services[0]?.first_dosage
      const second_dosage = this.elementData.bundles[0]?.services[0]?.second_dosage
      const third_dosage = this.elementData.bundles[0]?.services[0]?.third_dosage
      const fourth_dosage = this.elementData.bundles[0]?.services[0]?.fourth_dosage

      this.elementData.proposed_date = first_dosage || second_dosage || third_dosage || fourth_dosage
    },
  },
}
</script>

<style>

</style>
